/**
 * criterias that needs to fullfilled in order to set the password
 * @private
 */
    export const passwordCriteria = [
    {
        fullfilled: false,
        regEx: /.{8,}/,
        display: 'MIN_LENGTH'
    },
    {
        fullfilled: false,
        regEx: /(?=.*[a-z])(?=.*[A-Z])+/,
        display: 'LOWER_AND_UPPER_CASE'
    },
    {
        fullfilled: false,
        regEx: /[0-9]+/,
        display: 'FIGURES',
    },
    {
        fullfilled: false,
        regEx: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
        display: 'SPECIAL_CHARS',
    },
];